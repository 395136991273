#schedule-popup {
  position: relative;
  margin: 3rem auto;
  padding: 2rem;
  max-width: 50rem;

  section {
    padding: 3rem;
    background: white;
    border-radius: 0.4rem;
    box-shadow: 0.5rem 0 10rem rgba(0,0,0,0.25);

    h3 {
      margin-top: 0;

      a {
        margin-left: 1rem;
      }
    }

    table {
      width: 100%;

      tr {
        &.divider {
          td, th {
            padding-top: 1rem;
            border-bottom: 0.1rem solid #eee;
          }

          & + tr {
            td, th {
              padding-top: 1rem;
            }
          }
        }

        th {
          padding-right: 1rem;
        }

        th, td {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          vertical-align: top;
        }
      }
    }
  }

  .mfp-close {
    top: 3rem;
    right: 3rem;
  }
}

body .mfp-bg {
  background: #ccc;
}
