table.locations-planner,
table.assignments-locations-planner {
  border-collapse: collapse;
  width: 100%;

  thead tr th {
    padding: 1rem;
    border-bottom: 0.2rem solid $mediumlight-divider-color;
    background: unset;
    &.arrival,
    &.departure {
      min-width: 0;
    }
  }

  tbody tr {
    background: #fff;
    &:not(:last-child) {
      border-bottom: 0.1rem solid $light-divider-color;
    }
    &:first-child .move-location-up,
    &:last-child .move-location-down {
      cursor: auto;
      opacity: 0;
      &:hover {
        opacity: 0;
      }
    }

    td {
      padding: 0.5rem 1rem;
      vertical-align: middle;
      white-space: nowrap;
      &.home, &.external {
        padding-left: 4rem;
        position: relative;
        &:before {
          position: absolute;
          top: 0.9rem;
          left: 1.1rem;
          font-size: 1.6rem;
          font-family: 'Font Awesome 5 Pro';
        }
      }
      &.home:before {
        content: '\f015';
      }
      &.external:before {
        content: '\f1ad';
      }
      &.small-cell input {
        width: 6rem;
      }
      &.city input {
        width: 10rem;
      }
      &.note i {
        color: black;
      }

      input {
        background: unset;
        padding: 0.6rem 0;
        &.autocomplete {
          width: 100%;
        }
      }

      .select-schedule-option {
        padding-top: 1rem;
        padding-bottom: 0.7rem;
        border-bottom: 0.1rem solid #eee;

        span.telephone {
          margin-left: 0.3rem;
          font-size: 1rem;
          opacity: 0.7;
        }

        .address {
          font-size: 1.1rem;
          font-weight: bold;
        }

        .note {
          font-size: 0.9rem;
          opacity: 0.5;
        }
      }

      .move-location-up,
      .move-location-down,
      .remove-row {
        cursor: pointer;
        opacity: 0.3;
        padding: 0 0.3rem;
        &:hover {
          opacity: 0.6;
        }
      }

      .remove-row {
        color: $bouwens-primary;
      }
    }
  }
}
