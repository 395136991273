.field-group {
  display: flex;
  flex-grow: 1;
  padding-bottom: 1rem;
  border: none;
  flex-basis: 100%;
  &.hidden {
    display: none;
  }

  .field {
    &.grow {
      flex: 1;

      input, select, textarea {
        width: 100%;
      }
    }
    &.without-label {
      margin-top: 2rem;
    }
    &.file-field {
      position: relative;

      .file-field-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.2rem dashed $mediumlight-divider-color;
        background: $grey-background-color;
        padding: 1.5rem 3rem;
        font-weight: normal;
        border-radius: 1rem;
        white-space: normal;
        word-break: break-word;
        &.uploaded {
          border-color: $approval-font-color;
          background-color: lighten( #83C548, 40);
        }

        i {
          margin-right: 1rem;
          font-size: 3rem;
          opacity: 0.3;
        }
      }

      input[type=file] {
        position: absolute;
        cursor: pointer;
        left: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
      }
    }

    .time-input {
      position: relative;

      input[type=time] {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
      }
    }

    label {
      user-select: none;
    }

    label:not(.checkbox-label) {
      display: inline-block;
      margin-bottom: 0.5rem;
      font-weight: bold;

      i {
        margin-right: 0.3rem;
      }

      span.caption {
        margin-left: 0.5rem;
        opacity: 0.5;
        font-size: 1.2rem;
        font-weight: normal;
        font-style: italic;
      }
    }

    &.checkbox {
      margin-top: 2.5rem;
      white-space: nowrap;
      &.block {
        margin-top: 0;
      }

      label {
        display: inline;
      }
    }
  }
}

// Remove Google logo from autofill
.pac-container {
  // Make Google autocomplete visible in magnific popups
  z-index: 2000;

  body & {
    margin-left: 0.2rem;
    width: 40rem;
    &.pac-logo:after {
      content: none;
    }
  }
}
