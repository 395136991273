#outdated-warning {
  display: none;
  position: fixed;
  top: 8rem;
  left: 0;
  width: calc(100% - 4rem);
  margin: 0 2rem;
  justify-content: center;
  z-index: 1001;
  text-align: center;
  &.active {
    display: flex;
  }

  .content {
    max-width: 33rem;
    padding: 1.5rem 2rem 2.5rem;
    border: 0.1rem solid $warning-border-color;
    background-color: $warning-background-color;
    border-radius: 0.5rem;
    position: relative;

    h3 {
      margin-top: 0;

      i.close {
        position: absolute;
        right: 1.5rem;
        top: 1.1rem;
        padding: 0.5rem;
        cursor: pointer;
      }
    }
  }
}

.refreshed-at-container {
  @include desktop() {
    position: absolute;
    right: 3rem;
    top: 3.5rem;
  }
}

span.refreshed-at {
  margin-right: 1rem;
  font-size: 1.3rem;
  color: white;

  @include desktop() {
    opacity: 0.4;
    color: unset;
  }

  @include mobile-only() {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }
}
