body.portal-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  &.nl {
    background: $primary-color url("images/nl-background.svg") no-repeat;
    background-size: 200%;
    background-position: 0;

    @include desktop {
      background-size: auto 200%;
      background-position: calc(50% + 50rem);
    }
  }
  &.be {
    background: $receptel-primary url("images/be-background.svg") no-repeat;
    background-size: 200%;
    background-position: 0;

    @include desktop {
      background-size: auto 200%;
      background-position: calc(50% + 25vw);
    }
  }

  > section {
    flex-direction: column;
    max-width: 22.5rem;

    h1 {
      margin-bottom: 2rem;
      color: white;
      text-align: center;

      span {
        font-weight: 300;
      }
    }

    p {
      color: white;
      font-size: 1.4rem;
      line-height: 2rem;
      text-align: center;
    }

    .login-form {
      form {
        i {
          line-height: 4.1rem;
        }

        input {
          display: block;
          padding: 1.2rem 1.5rem;
          width: 100%;
          margin: 0 0 1.6rem;
          border: 0;
          box-shadow: inset 0.1rem 0.1rem 0.4rem rgba(0,0,0,0.15);
        }

        input[type="text"] {
          padding-right: 4rem;
        }

        input.black {
          margin-top: 2rem;
          transition: all 0.1s;
          color: white;
          &:hover {
            color: white;

          }
          &.nl {
            background: #444;
            border-bottom: 0.4rem solid #222;
            &:hover {
              background: #333;
              border-bottom: 0.4rem solid black;
            }
          }
          &.be {
            background: $receptel-secondary;
            border-bottom: 0.4rem solid darken($receptel-secondary, 5);
            &:hover {
              background: darken($receptel-secondary, 5);
              border-bottom: 0.4rem solid darken($receptel-secondary, 10);
            }
          }
        }
      }
    }
  }

  footer {
    align-self: flex-end;
    padding: 3rem;
    width: 100%;
    background: white;
    text-align: center;
  }
}
