body.assignments {
  .declaration-page {
    padding-bottom: 10rem;
    &.new-record .remove_nested_fields {
      display: none;
    }

    h2 {
      display: none;
      @include desktop {
        display: block;
      }
    }

    > header {
      padding: 2.5rem;
      background: linear-gradient(4.65deg, #f3f3f3 0%, #ffffff 100%);
      @include desktop {
        padding: 2.5rem 5rem;
      }

      a.back-to {
        display: block;
        margin-bottom: 0.7rem;
        font-size: 1.6rem;
      }

      .company {
        margin-bottom: 0.5rem;
        font-size: 2rem;

        a .fa-file-pdf {
          color: $primary-color;
        }
      }

      .date {
        color: $light-font-color;
        span.id {
          color: $primary-color;
        }
      }
    }

    .tab-controls,
    form > section {
      margin: 2.5rem 2.5rem 0;
      @include desktop {
        margin: 0;
      }
    }

    // Show all sections on desktop
    .tab-controls {
      @include desktop {
        display: none;
      }
    }

    form {
      // Show all sections on desktop
      @include desktop {
        display: grid;
        grid-gap: 0 5%;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          "details preferences"
          "submit submit";

        section.tab-target {
          display: block;
        }

        a.tab-switcher {
          display: none;
        }

        section.details {
          grid-area: details;
          padding: 10% 0 10% 10%;
          .declaration-summary {
            display: none;
          }
        }

        section.preferences {
          grid-area: preferences;
          padding: 10% 10% 10% 0;
        }

        section.submit {
          grid-area: submit;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 3rem;
          padding: 3rem 10%;
          background: #f3f3f3;
        }
      }
      > section {
        &.details {
          .locations {
            position: relative;
            margin-top: 3rem;

            .location-group {
              position: relative;
              border-radius: 0.7rem;
              padding: 2.5rem;
              margin: 1rem -1.5rem 3rem;
              @include desktop {
                margin: 1rem 0 3rem;
              }
              &.hidden {
                display: none;
              }

              h3 i {
                margin-right: 0.8rem;
              }

              .location-wrapper {
                position: relative;
              }
            }

            #travel-to-start-locations,
            #travel-to-end-locations {
              background-color: $light-orange-background-color;

              .location-wrapper:not(:nth-last-child(2)) {
                margin-bottom: 6rem;
              }

              .add-location, .add-location a {
                background-color: #fff6eb;
              }

              .add-location.before, .add-location.after {
                background-color: $light-orange-background-color;

                a {
                  background-color: $light-orange-background-color;
                }
              }

              h3, .add-location i {
                color: $light-orange-font-color;
              }

              .add-location a:hover {
                background-color: $light-orange-font-color;
                i {
                  color: white;
                }
              }
            }

            #billable-locations {
              background-color: $light-blue-background-color;

              .location-wrapper:not(:last-child) {
                margin-bottom: 6rem;
              }

              .add-location, .add-location a {
                background-color: #F6FAFF;
              }

              .add-location.before, .add-location.after {
                background-color: $light-blue-background-color;

                a {
                  background-color: $light-blue-background-color;
                }
              }

              h3, .add-location i {
                color: $blue-font-color;
              }

              .add-location a:hover {
                background-color: $blue-font-color;
                i {
                  color: white;
                }
              }
            }

            .add-location {
              position: absolute;
              left: -2.5rem;
              right: -2.5rem;
              height: 0.5rem;
              width: calc(100% + 5rem);
              transition: 150ms all ease-in-out;
              &.before {
                height: 0;
                top: -1.3rem;
              }
              &.after {
                height: 0;

                a {
                  bottom: -3.9rem;
                }
              }
              &.middle {
                bottom: -3rem;

                a {
                  bottom: -1.7rem;
                }
              }

              a {
                position: absolute;
                display: block;
                width: 4rem;
                height: 4rem;
                text-align: center;
                left: calc(50% - 2rem);
                padding: 1rem;
                border-radius: 50%;
                font-size: 2rem;
                transition: 150ms all ease-in-out;
                i {
                  transition: 150ms all ease-in-out;
                }
              }
            }

            .location {
              transition: 500ms all ease-in-out;

              input[type="number"],
              .time-input input {
                &.invalid {
                  border: 0.2rem solid $primary-color;
                }
              }
            }
          }
        }
        &.preferences {
          h3 {
            display: block;
            font-size: 2rem;
          }
        }
        &.submit {
          .declaration-summary,
          .expense-summary {
            padding: 1rem 2rem;
            border-radius: 0.5rem;
            background: $medium-background-color;
          }

          .expense-summary {
            padding: 0 2rem;

            i.static-tooltip-icon {
              margin-left: 0.5rem;
            }
          }

          button.submit-declaration,
          a.button {
            display: block;
            padding: 1.2rem 3rem;
            margin-bottom: 2rem;
            width: 100%;
            font-size: 1.6rem;
          }

          @include desktop {
            a.button {
              background: white;
            }
          }
        }

        // Saving states
        .locations > .location-group > .location-wrapper > .location,
        > .field-group {
          position: relative;
          transition: 500ms all ease-in-out;
          &:before {
            display: block;
            position: absolute;
            opacity: 0;
            margin-bottom: 1.5rem;
            top: 2rem;
            max-height: 0;
            width: calc(100% - 5rem);
            overflow: hidden;
            text-align: center;
            transition: 500ms all ease-in-out;
          }

          input:focus, input:hover,
          select:focus, select:hover,
          textarea:focus, textarea:hover,
          .time-input:hover input {
            border-color: $blue-font-color;
          }

          &.saved {
            .field.saved {
              input {
                animation-name: savedBorders;
                animation-duration: 4s;
                animation-fill-mode: forwards;
              }

              label {
                &:before {
                  content: "Opgeslagen";
                  color: $approval-border-color;
                  position: absolute;
                  opacity: 0;
                  animation-name: savedBefore;
                  animation-duration: 4s;
                  animation-fill-mode: forwards;
                }
                span {
                  animation-name: savedLabel;
                  animation-duration: 4s;
                  animation-fill-mode: forwards;
                }
              }
            }
            @keyframes savedBorders {
              0% {
              }
              12% {
                border-color: $approval-border-color;
              }
              50% {
                border-color: $approval-border-color;
              }
              100% {
              }
            }
            @keyframes savedLabel {
              0% {
                opacity: 1;
              }
              12% {
                opacity: 0;
              }
              75% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
            @keyframes savedBefore {
              0% {
                opacity: 0;
              }
              12% {
                opacity: 1;
              }
              75% {
                opacity: 1;
              }
              100% {
                opacity: 0;
              }
            }
          }

          &.saving-failed {
            .field.saving-failed {
              input {
                animation-name: savedBorders;
                animation-duration: 4s;
                animation-fill-mode: forwards;
              }

              label {
                &:before {
                  content: "Opslaan mislukt";
                  color: $primary-color;
                  position: absolute;
                  opacity: 0;
                  animation-name: savedBefore;
                  animation-duration: 4s;
                  animation-fill-mode: forwards;
                }
                span {
                  animation-name: savedLabel;
                  animation-duration: 4s;
                  animation-fill-mode: forwards;
                }
              }
            }
          }

          label {
            display: block;
            opacity: 1;
          }
        }

        #travel-to-start-locations,
        #travel-to-end-locations {
          input:focus, input:hover,
          select:focus, select:hover,
          textarea:focus, textarea:hover,
          .time-input:hover input {
            border-color: $light-orange-font-color;
          }
        }
      }

      .declaration-summary,
      .expense-summary {
        width: 100%;
        margin-bottom: 2rem;
        font-size: 1.6rem;
        @include desktop {
          margin: 0;

          > div:last-of-type {
            margin-bottom: 2rem;
          }
        }

        > div {
          display: flex;
          justify-content: space-between;
          padding: 1rem 0;
          &:not(:first-child) {
            border-top: 0.1rem solid $mediumlight-divider-color;
          }
        }
      }

      .declaration-summary {
        font-weight: bold;
        > div:not(.total-time) span:not(:first-child) {
          font-weight: normal;
        }
      }

      .expense-summary {
        font-weight: normal;

        span.description {
          margin-left: 0.2rem;
          font-style: italic;
          opacity: 0.5;
        }

        .remove_nested_fields {
          margin-left: 0.4rem;
        }
      }
    }

    button.submitting {
      position: relative;
      pointer-events: none;
      opacity: 0.5;
      .double-spinner {
        position: absolute;
        display: block;
        left: 1rem;
        top: -1rem;
      }
    }

    section,
    .declaration-popup {
      .field-group {
        .field {
          &:not(:first-child) {
            margin-left: 1rem;
          }

          label {
            white-space: nowrap;
            font-size: 1.6rem;
            &.with-extra-content {
              display: flex;
              justify-content: space-between;

              .extra-content {
                color: $light-font-color;
                font-weight: normal;
                &.fa-file-alt {
                  color: $blue-font-color;
                }

                @include small-mobile-only {
                  display: none;
                }
              }

              .tooltip-body {
                left: -10.4rem;
                width: 30rem;

                .arrow {
                  left: 85%;
                }

                .tooltip-content {
                  padding: 2rem;
                  font-size: 1.5rem;
                }
              }
            }

            span.amount-label {
              display: none;
            }

            i {
              margin-left: 0.5rem;
            }
          }

          span.location-name {
            display: inline-block;
            margin-top: 0.3rem;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}
