aside.main-menu {
  display: none;
  background: white;

  @media print {
    display: none !important;
  }

  @include desktop() {
    display: block;
    z-index: 1;
    max-width: 30rem;
    width: 100%;
    border-top: 0.3rem solid $primary-color;
    box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.12);
  }

  &.home {
    display: block;
  }

  a {
    text-decoration: none;
    color: $body-font-color;

    &:visited {
      color: $body-font-color;
    }

    &:hover {
      color: $primary-color;
    }

    @include desktop {
      &.selected {
        color: $primary-color;
      }
    }
  }

  .header {
    &.no-avatar {
      margin-top: 12rem;

      @include desktop() {
        margin-top: 4rem;
      }
    }

    .avatar {
      position: relative;
      z-index: 1;
      height: 9rem;
      width: 9rem;
      margin: 0 auto;
      margin-top: 5rem;
      margin-bottom: 2rem;
      border: 0.4rem solid white;
      border-radius: 50%;
      background-color: #f3f3f3;
      box-shadow: 6px 6px 16px 0 rgba(0, 0, 0, 0.17);

      .avatar-edit {
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        right: 0;
        height: 3rem;
        width: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          color: #333;
          font-size: 15px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          letter-spacing: -0.227px;
        }
      }

      @include desktop() {
        margin: 3.5rem auto 3rem;
      }
    }

    .logo {
      text-align: center;

      img {
        height: 1.6rem;
      }

      span {
        font-size: 2.2rem;
      }

      span.jobtitle {
        display: block;
        color: #888;
        font-size: 1.6rem;
      }
    }
    .logo-receptel {

      .jobtitle {
        padding: 0.7rem 0;
      }

      img {
        height: 2rem;
      }
    }
  }
}

.diagonal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 9rem;
  background: linear-gradient(-49.49deg, #fb4444 0%, rgba(216, 35, 42, 0.9) 100%);

  &.diagonal-bottom-rtl {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1.1rem), 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1.1rem), 0% 100%);
  }

  @include desktop() {
    display: none;
  }
}
