body.team {

  main {
    header {
      padding: 3rem;
    }

    > section {
      padding: 0;
      @include desktop {
        padding: 3rem;
      }
    }
  }

  section > section {
    margin: 0 0 3rem;
    padding: 2.6rem;
    background: linear-gradient(0deg, #FFFFFF 0%, #F9F9F9 100%);
    @include desktop {
      background: white;
      border-radius: 0.4rem;
    }

    h2 {
      text-align: center;
      @include desktop {
        text-align: left;
      }
    }

    .members {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @include desktop {
        justify-content: flex-start;
      }

      .member {
        margin-bottom: 2rem;
        width: 50%;
        text-align: center;
        font-size: 1.3rem;
        line-height: 1.8rem;
        color: #888;
        @include desktop {
          display: flex;
          margin: 0 2rem 2rem 0;
          width: 30rem;
          text-align: left;
        }

        a {
          color: #888;
          text-decoration: none;
          &:hover {
            color: #444;
          }
        }

        .avatar {
          margin: 0 auto;
          height: 7.8rem;
          width: 7.8rem;
          min-width: 7.8rem;
          border-radius: 50%;
          border: 0.3rem solid white;
          background-color: $light-background-color;
          box-shadow: 0.3rem 0.3rem 0.5rem 0 rgba(0,0,0,0.1);
          @include desktop {
            margin: 0 2rem 0;
          }
        }

        .name,
        .position {
          margin: 0 auto;
        }

        .name {
          margin-top: 1rem;
          line-height: 2rem;
          font-size: 1.5rem;
          font-weight: bold;
          color: #222;
        }

        section {
          display: flex;
          justify-content: center;
          @include desktop {
            display: block;
          }

          .far {
            margin: 1rem 0.5rem;
            padding: 1rem;
            font-size: 2rem;
            color: #aaa;
            background: #f3f3f3;
            border-radius: 0.4rem;
            transition: 0.15s all ease-in-out;
            @include desktop {
              margin: 1rem 0 0;
              padding: 0.5rem 1rem;
            }
            &:hover {
              background: $primary-color;
              color: white;
            }
          }
        }
        .position {
          @include desktop {
            color: $primary-color;
          }
        }
      }
    }
  }

  .employees {
    .slack {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 3rem;
      @include desktop {
        margin-left: 8rem;
        margin-top: -5rem;
        justify-content: flex-end;
      }

      .wrapper {
        display: flex;
        border-radius: 0.4rem;
        background: white;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);

        figure {
          display: flex;
          align-items: center;
          max-width: 8.4rem;
          width: 100%;
          padding: 0.3rem 0.8rem;
          margin: 0;
          border-top-left-radius: 0.4rem;
          border-bottom-left-radius: 0.4rem;

          img {
            width: 100%;
          }
        }
      }

      .slack-team {
        background-color: #A4AAB0;
        padding: 0.7rem 1.2rem;
        border-top-right-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
        color: white;
        text-align: center;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: bold;
      }
    }
  }
}
