body.documents {
  .documents-list {
    table {
      display: none;
      width: 100%;
      border-collapse: collapse;
      font-size: 1.4rem;
      @include desktop {
        display: table;
      }

      .align-right {
        padding-right: 2rem;
        text-align: right;
        white-space: nowrap;
        width: 1%;
      }

      thead {
        th {
          padding: 0.7rem;
          text-align: left;
          border-bottom: 0.2rem solid #ddd;
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background: white;
          }

          td {
            padding: 0.7rem 1rem;
            &.date,
            &.time {
              white-space: nowrap;
            }
            &.action {
              a {
                color: $primary-color;
              }
            }
          }
        }
      }
    }

    .mobile-documents {
      display: block;
      width: 100%;
      @include desktop {
        display: none;
      }
    }

    .document-mobile {
      position: relative;
      width: 100%;
      min-width: 25rem;
      padding: 1.5rem 0;
      font-size: 1.4rem;
      line-height: 2.2rem;
      border-bottom: 0.1rem solid $light-divider-color;

      .document {
        margin-right: 3rem;
        font-size: 1.5rem;

        a.open-pdf {
          margin-right: 0.5rem;
          text-decoration: none;
          color: #888;
        }
      }

      .created {
        color: lighten($portal-body-font-color, 50);
      }
    }
  }
}
