table.newassignment {
  width: 100%;
  td {
    padding: 0.1rem 0;
  }

  td.column1 {
    width: 120px;
  }
  td.column2 {
    width: 160px;
  }
  td.column3 {
    width: 320px;
  }
  td.addbutton {
    height: 40px;
    img.button {
      margin-bottom: -7px;
      border-right: 7px solid white;
    }
  }
  select.big {
    width: 272px;
  }
  input.big {
    width: 272px;
  }
  textarea.big {
    width: 272px;
    height: 100px;
  }
  input.number {
    width: 54px;
  }
  input.medium {
    width: 200px;
  }
  input.place {
    width: 144px;
  }
  input.time {
    margin-right: 5px;
    width: 91px;
  }
  input.postcode {
    width: 66px;
  }
  input.date {
    width: 18px;
  }
  input.month {
    width: 18px;
  }
  input.year {
    width: 35px;
  }

  td.locations {
    width: 480px;
    tr.departuretime {
      display: none;
    }
    div.scheduler {
      table {
        width: 100%;
      }
    }
  }

  input.datepicker {
    width: 10rem;
    padding: 0.9rem;
  }
}

input[type=text] {
  padding: 0.2rem 0.4rem;
}
