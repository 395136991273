body.knowledge-base {
  &.sticky {
    #sticky {
      position: fixed;
      top: 0;
      width: 100%;
      padding: 2.5rem;
      @include desktop {
        padding: 3rem 3rem 3rem 0;
      }
    }
  }

  main {
    section {
      @include desktop {
        display: flex;
        justify-content: center;
      }

      aside {
        position: relative;
        white-space: nowrap;
        user-select: none;
        @include desktop {
          order: 2;
          margin: 0 0 5rem 0;
          width: 30rem;
        }

        #sticky {
          position: static;
          z-index: 99;
          margin-left: -2.5rem;
          margin-right: -2.5rem;
          padding: 0 2.5rem 2.5rem;
          width: calc(100% + 5rem);
          background: white;
          border-bottom: 0.2rem solid #eee;
          @include desktop {
            overflow-y: auto;
            margin: 0;
            padding: 3rem 3rem 3rem 0;
            height: 100%;
            border-bottom: none;
            background: none;
          }
        }

        input,
        select {
          width: 100%;
          @include desktop {
            width: auto;
          }
        }

        .buttons {
          display: none;
          @include desktop {
            display: inline-block;
          }

          .button {
            padding-left: 1rem;
            padding-right: 0;
          }
        }

        #search_results {
          span {
            display: inline-block;
            margin-top: 1.5rem;
            font-weight: bold;
          }
        }

        #sticky > h2 {
          margin-top: 0;
        }

        h2 {
          display: none;
          margin-top: 3rem;
          @include desktop {
            display: block;
          }
        }

        .mobile-toc {
          display: block;
          margin-top: 2rem;
          @include desktop {
            display: none;
          }
        }

        .desktop-toc {
          display: none;
          @include desktop {
            display: block;
          }
        }

        .toc-list {
          padding-left: 2.5rem;
          list-style: none;
          line-height: 2.5rem;

          .is-active-link::before {
            background-color: $primary-color;
          }

          .toc-link {
            display: inline-block;
            text-decoration: none;
            color: $portal-body-font-color;
            &:before {
              height: 2.5rem;
            }
          }

          .toc-list-item {
            &.is-active-li {
              &:before {
                font-weight: bold;
              }
            }
          }

          li {
            counter-increment: root;
            &:before {
              content: counter(root) ". ";
            }

            .toc-list {
              padding-left: 1rem;
              counter-reset: subsection;

              li {
                counter-increment: subsection;
                &:before {
                  content: counter(root) "." counter(subsection) " ";
                }
              }
            }
          }
        }
      }

      article {
        position: relative;
        @include desktop {
          order: 1;
          margin-right: 5rem;
          max-width: 80rem;
        }

        mark {
          background: #ddf1ff;
          color: black;
          &.current {
            background: #76bef5;
          }
        }

        p {
          margin: 0 0 1rem;
          font-size: 1.5rem;
          line-height: 2.2rem;
          &.question {
            margin: 2.4rem 0 1rem;
            font-weight: bold;
            font-size: 1.7rem;
          }
        }

        ul {
          list-style: initial;
          margin: 2rem 0;

          li {
            margin: 1rem 0;
          }
        }

        ol {
          margin: 2rem 0;
        }

        table {
          width: 100%;
          margin: 2em 0;
          border-collapse: collapse;

          tr {
            &:first-child {
              font-weight: bold;

              td {
                border: none;
                border-bottom: 2px solid #aaa;
              }
            }

            td {
              padding: 0.6rem 1.2rem;
              border: 0.1rem solid #ddd;

              p {
                margin: 0;
              }
            }
          }
        }

        h2,
        h3,
        h4 {
          outline: none;
        }

        ol.level-0,
        ol.level-1 {
          padding: 0;
          margin: 0;
          list-style-type: none;
        }

        span.mobile-nav {
          display: block;
          position: absolute;
          margin-top: -15rem;
          visibility: hidden;
        }

        h2:before,
        h3:before {
          @include desktop {
            position: absolute;
            display: inline-block;
            left: -5.5rem;
            width: 4.5rem;
            text-align: right;
          }
        }

        ol.level-0 {
          li.level-0 {
            margin-bottom: 2rem;
            counter-increment: root;
            @include desktop {
              padding: 4.5rem 8rem 6rem 9rem;
              background: white;
              box-shadow: 0.1rem 0.3rem 1.2rem rgba(0, 0, 0, 0.1);
            }
            h2:before {
              content: counter(root) ". ";
            }

            h2,
            h3 {
              position: relative;
            }

            h2 {
              padding-top: 2.5rem;
              font-size: 2.4rem;
            }

            h3 {
              padding-top: 3rem;
              margin-top: 0;
            }

            ol.level-1 {
              counter-reset: subsection;

              li.level-1 {
                counter-increment: subsection;
                h3:before {
                  content: counter(root) "." counter(subsection) " ";
                }

                h3 {
                  margin: 2rem 0;
                  font-size: 2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
