body.profile main,
body.change-password main {
  section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .field-group {
      max-width: 24.5rem;
    }

    button {
      margin-top: 1rem;
    }
  }

  .avatar {
    margin: 0 auto;
    height: 14.7rem;
    width: 14.7rem;
    border: 0.5rem solid white;
    border-radius: 50%;
    box-shadow: 0.6rem 0.6rem 1.6rem 0 rgba(0,0,0,0.17);

    .double-spinner:after {
      width: 10rem;
      height: 10rem;
      margin: 2rem;
      border-width: 1rem;
      animation-duration: 2s;
      -webkit-animation-duration: 2s;
    }
  }

  label.upload-avatar {
    display: block;
    height: 4rem;
    width: 19rem;
    margin: 4rem auto;
    padding: 0 1.5rem;
    border-radius: 0.4rem;
    background-color: $primary-color;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 4rem;
    cursor: pointer;

    i {
      position: relative;
      top: -0.1rem;
      margin-right: 1rem;
      font-size: 2rem;
      vertical-align: middle;
    }
  }

  input[type=file] {
    position: absolute;
    z-index: -1;
    width: 0.1px;
    height: 0.1px;
    overflow: hidden;
    opacity: 0;
  }

  .success-message,
  .warning-message {
    align-items: center;
    justify-content: center;
    padding: 2rem 2rem 2rem 5rem;
    border-radius: 0.5rem;
    position: relative;
    margin-bottom: 3rem;
    &:before {
      font-size: 2rem;
      position: absolute;
      top: 2rem;
      left: 2rem;
      font-family: 'Font Awesome 5 Pro';
    }
  }

  .success-message {
    display: none;
    background-color: $approval-background-color;
    &:before {
      content: '\f058';
      color: $approval-font-color;
    }
  }

  .warning-message {
    max-width: 24.5rem;
    background-color: $light-orange-background-color;
    &:before {
      content: '\f06a';
      color: $light-orange-font-color;
    }
  }

  #change-password {
    margin-bottom: 3rem;

    button {
      margin: 0 auto;
      height: 4.2rem;
      &.loading {
        cursor: wait;
      }
    }

    .alert {
      min-height: 3rem;
      max-width: 24.5rem;
      margin-top: 1rem;
      font-weight: bold;
      color: $primary-color;
    }

    .password-validation {
      margin: 0.5rem 0 2rem;

      > div {
        display: block;
        padding-left: 2rem;
        position: relative;
        font-size: 1.3rem;
        font-weight: bold;
        &:before {
          position: absolute;
          top: 0rem;
          left: 0rem;
          content: '\f058';
          color: $approval-font-color;
          font-family: 'Font Awesome 5 Pro';
        }

        span {
          opacity: 0.4;
        }

        &.invalid {
          span {
            opacity: 1;
          }

          &:before {
            content: '\f057';
            color: $primary-color;
          }
        }
      }
    }
  }
}
