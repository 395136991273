html {
  height: 100%;
  overflow-y: auto;
}

body {
  min-height: 100%;
  @include desktop() {
    display: flex;
  }

  > section {
    @include desktop() {
      display: flex;
      height: 100%
    }
  }

  .readable-width {
    max-width: 65rem;
  }

  main {
    width: 100%;
    &.home-schedule {
      @include mobile-only() {
        display: none;
      }
    }

    > header {
      width: 100%;
      padding: 2.5rem 2.5rem 0;
      @include desktop {
        padding: 3rem 4rem 2.5rem;
      }

      @media print {
        display: none;
      }
    }

    > section,
    > .exception-page {
      width: 100%;
      padding: 2.5rem;
      @include desktop {
        padding: 4rem;
      }
    }
  }
}

.mobile-visible {
  display: block !important;
  @include desktop {
    display: none !important;
  }
}
.desktop-visible {
  display: none !important;
  @include desktop {
    display: block !important;
  }
}
