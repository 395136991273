.static-tooltip-icon, .static-tooltip-button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: lighten($body-font-color, 40%);
  &.empty {
    display: none;
  }

  &.empty-note {
    opacity: 0.3;
  }

  strong {
    color: black;
    font-style: normal;
  }

  .tooltip-body {
    display: none;
    cursor: initial;
    z-index: 20;
    position: absolute;
    padding: 0.8rem;
    top: 100%;
    color: black;
    min-width: 20.5rem;
    width: max-content;
    height: auto;
    transform: translateX(-50%);

    .tooltip-content {
      padding: 1rem;
      border-radius: 0.4rem;
      background: #fff;
      font-family: Roboto, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.3rem;
      white-space: normal;
      letter-spacing: 0;
      text-align: left;
      box-shadow: 0 0.8rem 1.2rem 1rem rgba(0,0,0,0.10);

      .notes-title {
        text-align: center;
        margin-top: 0.3rem;
        margin-bottom: 1rem;
      }

      table {
        th {
          background: unset !important;
        }
      }

      textarea {
        -webkit-appearance: none;
        border: 0.1rem solid rgb(204, 204, 204);
        width: 137px;
        padding: 1rem;
        line-height: 1.6rem;
        font-size: 1.2rem;
        min-height: 8rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0.2rem 0.2rem 0.4rem inset;
        border-radius: 0.4rem;
        display: block;
        margin: 0px;
      }
    }

    &.with-textarea {
      width: auto;
    }

    &.small {
      width: 12.5rem;
      padding: 0 2rem;
      white-space: nowrap;

      ul {
        margin: 0;
        padding-left: 0;
        list-style-type: none;
        line-height: 2rem;

        span.timeblock {
          width: 1rem;
          height: 1rem;
          margin-right: 0.3rem;
          display: inline-block;
        }
      }

      .tooltip-content {
        padding: 1.5rem;
        margin-top: 0.8rem;
      }
    }

    /*
    TODO: implement logic that a tooltip is never placed outside of the screen (partially)
    For now we'll keep add custom logic since it hasn't yet been worth the time investment..
    */
    &.contract-remark {
      left: -15rem;
      width: 40rem;

      .arrow {
        right: 4.5rem;
        left: auto;
      }
    }
    &.contract-details {
      left: -10.3rem;
      width: 45rem;

      .arrow {
        right: 4.5rem;
        left: auto;
      }
    }
    &.grow-to-left {
      width: 40rem;
      transform: translateX(-85%);

      .arrow {
        right: 4.5rem;
        left: auto;
      }

      table {
        width: 100%;
      }
    }

    .arrow {
      position: absolute;
      top: 0.2rem;
      left: calc(50%);
      border-left: 0.6rem solid transparent;
      border-right: 0.6rem solid transparent;
      border-bottom: 0.6rem solid #fff;
    }

    .tooltip-content {
      p.remark {
        margin: 0;
        line-height: 1.5rem;
      }
    }

    &.with-icon {
      .tooltip-content {
        position: relative;
        padding-left: 4rem;
        width: 15rem;

        i {
          position: absolute;
          top: 2rem;
          left: 1.5rem;
          opacity: 0.5;
        }
      }
    }
  }
  &:hover,
  &.opened {
    opacity: 1;
    .tooltip-body {
      display: block;
    }
  }
}
