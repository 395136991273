body.team-hours-summary {
  select.switch-team {
    margin-bottom: 3rem;
  }

  table {
    width: 100%;
    max-width: 120rem;
    border-collapse: collapse;
    font-size: 1.4rem;

    @include mobile-only {
      display: block;
      overflow: scroll;
    }

    thead {
      th {
        padding: 0.7rem;
        text-align: left;
        border-bottom: 0.2rem solid #ddd;
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background: white;
        }

        td {
          padding: 0.7rem 1rem;

          &.red {
            color: #ee1d27;
          }

          &.green {
            color: #00a658;
          }
        }
      }
    }
  }
}
