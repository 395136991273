body.assignments #new_assignment {
  max-width: 110rem;

  .service-types {
    display: flex;
    flex-wrap: wrap;
    margin: -0.5rem;

    .service-type-group {
      flex-basis: calc(50% - 1rem);
      margin: 0.5rem;
      padding: 1.5rem;
      width: 0;
      background-color: white;
      @include mobile-only {
        width: calc(100vw - 5rem);
      }

      h3 {
        margin: 0.5rem 0 1rem;
      }

      h3, span {
        min-width: 0;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .field-group {
    margin-top: 2rem;
    align-items: center;

    .field {
      margin-right: 1rem;

      .timepicker {
        width: 7rem;
        text-align: center;
      }

      .datepicker {
        text-align: center;
      }

      textarea {
        display: block;
        max-width: 42.5rem;
      }

      span.create {
        padding: 1.3rem;
        border-radius: 0.5rem;
        background-color: $approval-background-color;
      }

      table.locations-planner,
      table.assignments-locations-planner {
        width: unset;

        thead tr th {
          text-align: left;
        }

        tbody tr {
          background: unset;

          input {
            padding: 0.6rem 1rem;
            background: #fff;
            &.autocomplete {
              width: 30rem;
            }
          }
        }
      }

      a.add-row {
        margin-top: 1rem;
        display: inline-block;
        text-decoration: none;
      }
    }
  }
}
