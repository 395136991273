html {
  font-size: 10px;
  -webkit-text-size-adjust: 100%;

  // border-box default doesn't work well with shared components
  // that use CSS designed for content-box environments.
  &:not(.period-plannings), &:not(.admin-employee-view) {
    box-sizing: border-box;
    *, *:before, *:after {
      box-sizing: inherit;
    }
  }

  body {
    margin: 0;
    padding: 0;
    width: 100%;
    background: white;
    color: $portal-body-font-color;
    font-family: "Lato";
    font-size: 1.5rem;
    line-height: 2.1rem;
    -webkit-font-smoothing: antialiased;

    @include desktop() {
      background: $grey-background-color;
    }
  }
}
