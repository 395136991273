input[type="text"],
input[type="email"],
input[type="password"],
input[type="phonenumber"],
input[type="date"],
input[type="number"],
input[type="search"],
input[type="time"],
select,
textarea {
  padding: 0.9rem;
  background-color: $default-background-color;
  border: 0.2rem solid $medium-divider-color;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-family: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  @include desktop {
    font-size: 1.4rem;
  }
  &:focus {
    outline: none;
  }
  &:not(textarea):not([id^="react-select-"]) {
    height: 4.1rem;
  }
  &.invalid {
    border: 0.2rem solid $primary-color;
  }
  &.required {
    border: 0.2rem solid $warning-background-color;
  }
}

// Quick fix for generic input height messing with selectize height (only in applicable surcharge popup)
.selectize-input > input {
  height: unset !important;
}

.input-wrapper {
  position: relative;
  i {
    position: absolute;
    right: 1.6rem;
    font-family: "Font Awesome 5 Pro";
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    line-height: 3.5rem;
    pointer-events: none;
  }

  input,
  select {
    -webkit-appearance: none;
    padding-right: 3rem;
  }
}

input,
textarea {
  padding: 1rem 1rem;
}

input[type="date"] {
  padding: 0.7rem 1rem;
  font-family: Lato;
}

input[type="number"] {
  padding-right: 0;
}

input[type="submit"],
button[data-type="submit"] {
  display: block;
  padding: 1.2rem 3rem;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-weight: bold;
  -webkit-appearance: none;
  user-select: none;
  cursor: pointer;
  border: none;
  &.green {
    color: $contrast-font-color;
    background-color: $approval-font-color;
    border-bottom: 0.3rem solid darken($approval-font-color, 15);
  }
  &:disabled {
    color: unset;
    background-color: $dark-background-color;
    border-bottom: 0.3rem solid darken($dark-background-color, 15);
    cursor: not-allowed;
    opacity: 0.5;
  }
}

select {
  position: relative;
  background-color: white;
  background-image: url("images/caret-down-light.svg");
  background-repeat: no-repeat;
  background-position: right 1rem top 50%, 0 0;
  background-size: 1rem auto, 100%;
  padding-right: 3rem;
  text-indent: 1px;
  text-overflow: "";
  overflow: hidden;
  outline: none;
}

a.red {
  font-weight: bold;
  text-decoration: none;
  color: $primary-color;
}

a.back-to {
  font-weight: bold;
  color: $link-color;
  text-decoration: none;
  &:hover {
    color: darken($link-color, 30);
  }
  &:before {
    content: "\f104";
    font-family: "Font Awesome 5 Pro";
    margin-right: 1rem;
  }
}
