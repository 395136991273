.submitted-confirmation {
  min-width: 28rem;
  margin-bottom: 3rem;
  padding: 4rem 0;
  text-align: center;
  background-color: $approval-background-color;

  .submitted-text {
    font-size: 2rem;
    font-weight: bold;
    color: $approval-font-color;
  }

  &.failed {
    background-color: #FFC6B3;

    .submitted-text {
      color: $primary-color;
    }
  }

  i {
    margin-left: 1rem;
  }

  @include desktop() {
    a.back-to {
      display: none;
    }
  }
}

body.assignments .submitted-confirmation {
  margin: 3rem 3rem 0;
  padding: 2rem;
  border-radius: 0.5rem;
}
