main > header {
  @include desktop {
    background: white;
  }

  h1 {
    position: relative;
    margin: 0 0 0 -1rem;
    padding-left: 5rem;

    i {
      position: absolute;
      top: 0.4rem;
      left: 0;
      opacity: 0.3;
      width: 4rem;
      text-align: right;
    }

    span {
      color: #aaa;
      font-weight: normal;
    }

    a.cta {
      padding: 0.5rem 1rem;
      margin-left: 1rem;
      vertical-align: middle;
      border: 0.1rem solid $medium-divider-color;
      border-radius: 5rem;
      text-decoration: none;
      color: unset;
      font-size: 1.3rem;
      opacity: 0.5;
      font-weight: normal;
      &:hover {
        opacity: 1;
      }
    }
  }
}
