/* Breakpoints */
$mobile-landscape: 400px;
$small-down: 640px;
$mobile-only: 0 785px;
$tablet: 512px;
$tablet-only: 512px 785px;
$desktop: 786px;
$container: 980px; // container max width + padding
$wide-desktop: 980px;
$wider-desktop: 1200px;

/* Clearfix for clearing floats. */
@mixin clearfix() {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  /* IE6 */
  * html & {
    zoom: 1;
  }
  /* IE7 */
  *:first-child + html & {
    zoom: 1;
  }
}

@mixin tablet() {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin small-down() {
  @media screen and (max-width: $small-down) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin wide-desktop() {
  @media screen and (min-width: $wide-desktop) {
    @content;
  }
}

@mixin wider-desktop() {
  @media screen and (min-width: $wider-desktop) {
    @content;
  }
}

@mixin wider-desktop-down() {
  @media screen and (max-width: $wider-desktop) {
    @content;
  }
}

@mixin mobile-only() {
  @media screen and (max-width: $desktop) {
    @content;
  }
}

@mixin small-mobile-only() {
  @media screen and (max-width: $mobile-landscape) {
    @content;
  }
}

@mixin invoicing-row-color($background-color, $lightness: 1) {
  background-color: $background-color;
  &:hover {
    background-color: lighten($background-color, $lightness);
    input:not([type="checkbox"]) {
      background-color: lighten($background-color, $lightness);
    }
  }

  input:not([type="checkbox"]) {
    background-color: $background-color;
    &.changed {
      background-color: desaturate(darken($background-color, 12%), 15%);
    }
  }
}
