body.time-sheets {
  .filters {
    @include mobile-only {
      position: absolute;
      right: 1rem;
      top: 9rem;
    }
  }

  .assignments-list {
    h3 {
      margin: 0 0 4rem;
      span {
        color: $light-font-color;
      }

      @include desktop {
        margin: 2rem 0;
      }
      @include wider-desktop {
        margin: 0 0 -5rem;
      }
    }

    > table {
      margin: 0 0 3rem;
      border-collapse: collapse;
      font-size: 1.4rem;
      &.totals {
        min-height: 7rem;

        thead th {
          border: 0;
          vertical-align: bottom;
        }

        table {
          border-collapse: collapse;
        }

        tr {
          padding: 0;
          background: none;
        }

        .service-type-hours,
        .surcharge-basis-hours {
          padding: 0;
          @include wider-desktop-down {
            width: 100% !important;

            table {
              display: table;
              width: 100%;
              margin: 1rem 0;

              thead {
                display: table-header-group;
              }

              tr {
                display: table-row;
                margin: 0;
                padding: 0;

                td {
                  display: table-cell;
                  width: auto !important;
                  min-width: 0;
                  &.surcharge,
                  &.service-type {
                    width: 100% !important;
                  }
                }
              }
            }
          }
          @include mobile-only {
            table tr td {
              background: $grey-background-color;
            }
          }
        }

        .service-type-hours {
          width: 38.3rem;
        }

        .surcharge-basis-hours {
          width: 32rem;
        }

        tbody td {
          vertical-align: top;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          &.date,
          &.assignment {
            @include wider-desktop-down {
              display: none;
            }
          }
          &.surcharge:before {
            display: none;
          }
          &.surcharge,
          &.hours,
          &.service-type,
          &.surcharge-hours,
          &.basis {
            background: white;
            border-top: 0.2rem solid $grey-background-color;
            &.empty {
              border-top: 0.2rem solid white;
            }
          }
        }

        tr.first {
          td.service-type,
          td.surcharge {
            border-top-left-radius: 0.5rem;
          }
          td.hours,
          td.surcharge-hours {
            border-top-right-radius: 0.5rem;
          }
        }

        tr.totals {
          td.surcharge,
          td.hours,
          td.service-type,
          td.surcharge-hours,
          td.basis {
            background: #eee;
            padding-top: 1rem;
            padding-bottom: 1rem;
          }
          td.service-type,
          td.surcharge {
            border-bottom-left-radius: 0.5rem;
          }
          td.hours,
          td.surcharge-hours {
            border-bottom-right-radius: 0.5rem;
          }
        }
      }

      @include wider-desktop-down {
        display: block;
        margin: 2rem 0;

        thead {
          display: none;
        }

        tbody {
          display: block;
        }
      }

      th,
      td {
        vertical-align: top;
        &.date,
        &.surcharge {
          white-space: nowrap;
        }
        &.date {
          width: 14rem;
        }
        &.assignment {
          width: 9rem;
        }
        &.service-type {
          width: 30rem;
          @include wider-desktop {
            padding-left: 1.5rem;
          }
        }
        &.divider {
          width: 1.5rem;
          @include wider-desktop-down {
            display: none;
          }
        }
        &.surcharge {
          @include wider-desktop {
            padding-left: 1.5rem;
          }
          width: 16rem;
        }
        &.hours,
        &.basis,
        &.surcharge-hours {
          text-align: right;
          white-space: nowrap;
          @include wider-desktop {
            width: 8rem;
          }
        }
        &.surcharge-hours,
        &.hours {
          @include wider-desktop {
            padding-right: 1.5rem;
          }
        }
      }

      thead {
        th {
          padding: 0 1rem 0.7rem 1rem;
          text-align: left;
          border-bottom: 0.2rem solid #ddd;
        }
      }

      tbody {
        tr {
          @include wider-desktop-down {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background: $grey-background-color;
            padding: 1rem;
            margin-bottom: 1rem;
            border-radius: 0.3rem;
          }
          &.odd {
            background: white;
            @include mobile-only {
              background: $grey-background-color;
            }
          }
          &.first td {
            padding-top: 0.7rem;
            @include wider-desktop-down {
              padding-top: 0;
            }
          }
          &.last td {
            padding-bottom: 0.7rem;
            @include wider-desktop-down {
              padding-bottom: 0;
            }
          }
          &.totals {
            font-weight: bold;
          }
          @include mobile-only {
            &.first:not(.last) {
              td.surcharge {
                border-top: 0.1rem solid #ccc;
                margin-top: 1rem;
                padding-top: 1rem;
                width: 100%;
                &:before {
                  content: "Toeslagen";
                  display: block;
                  margin-bottom: 1rem;
                  font-weight: bold;
                  font-size: 1.6rem;
                  width: 100%;
                }
              }
            }
            &:not(.first) {
              margin-top: -3rem;
              td.surcharge {
                width: 100%;
              }
            }
          }

          td {
            padding-left: 1rem;
            padding-right: 1rem;
            @include wider-desktop-down {
              display: flex;
              justify-content: space-between;
              width: 30% !important;
              min-width: 20rem;
            }
            @include mobile-only {
              width: 46% !important;
              flex-wrap: wrap;
            }
            @include small-mobile-only {
              width: 100% !important;
            }

            label {
              margin-right: 1rem;
              font-weight: bold;
              @include wider-desktop {
                display: none;
              }
            }

            span {
              position: relative;
              i {
                position: absolute;
                top: 0.3rem;
                margin-left: 0.5rem;
                @include mobile-only {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .mobile-assignments {
      display: block;
      width: 100%;
      @include desktop {
        display: none;
      }
    }

    .assignment-mobile {
      position: relative;
      width: 100%;
      min-width: 25rem;
      padding: 1.5rem 0;
      font-size: 1.4rem;
      line-height: 2.2rem;
      border-bottom: 0.1rem solid $light-divider-color;

      .company {
        margin-right: 3rem;
        font-size: 1.5rem;

        span.assignment-id {
          color: $primary-color;
        }

        a.open-pdf {
          margin-right: 0.5rem;
          text-decoration: none;
          color: #888;
        }

        a.action {
          position: absolute;
          top: 1rem;
          right: 1rem;
          font-size: 2rem;

          i {
            color: $approval-font-color;
            &:hover {
              color: darken($approval-font-color, 20);
            }
          }
        }
      }

      .date {
        color: lighten($portal-body-font-color, 50);
      }
    }
  }

  .download-pdf {
    font-size: 1.3rem;

    i {
      margin-right: 0.5rem;
    }

    &:hover {
      text-decoration: none;

      i {
        color: $portal-body-font-color;
      }

      span {
        color: $primary-color;
      }
    }
  }
}

body.php {
  .block {
    margin: 3rem 0;

    &.with-tables {
      overflow: auto;
    }

    table.expense-totals {
      border-collapse: collapse;
      white-space: nowrap;

      td,
      th {
        padding: 0.5rem;
        text-align: right;
      }

      &.totals-table td {
        border-top: 0.1rem solid $medium-divider-color;
      }
    }

    table:not(.expense-totals) {
      tbody tr {
        display: block;

        td:first-child {
          display: block;
          padding-top: 1rem;
          font-weight: bold;
        }

        input.date {
          width: 5rem;
        }

        &:nth-last-child(3) table {
          tr {
            display: table-row;

            td {
              display: table-cell;

              select {
                width: 16rem;
                margin-right: 2rem;
              }

              input.small {
                margin-top: 0.8rem;
              }
            }
          }
        }
      }
    }
  }

  input[type="submit"] {
    margin: 2rem 0;
  }

  .uploadedfiles .file,
  .uploadarea {
    position: relative;
    max-width: 40rem;
    padding: 1rem;
    margin-bottom: 1rem;
    background: $light-background-color;
    border: 1px solid $medium-divider-color;
    border-radius: 4px;

    input[type="submit"] {
      position: absolute;
      top: 1rem;
      right: 1rem;
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      &:hover {
        color: $primary-color;
      }
    }
  }

  a.back-to {
    display: block;
    margin-bottom: 2rem;
  }
}
