body.assignments .mfp-content {
  max-width: 70rem;
}

.declaration-popup {
  margin: 0 auto;

  .content {
    margin: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    background-color: white;
    font-size: 1.6rem;

    header {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;

      h3 {
        margin: 0;
      }

      .mfp-close {
        top: 2rem;
        right: 3rem;
        opacity: 0.2;
      }
    }

    header,
    section {
      padding: 2.5rem;
      &.grey {
        background-color: $light-background-color;
      }
    }

    button {
      width: 100%;
      margin-top: 1rem;
    }
  }
}
