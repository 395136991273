body.assignments {
  .tab-controls {
    display: flex;
    margin-bottom: 0.5rem;
    max-width: 45rem;
    background-color: $medium-background-color;
    @include desktop {
      margin-bottom: 1.5rem;
    }

    .tab {
      flex-grow: 1;
      text-align: center;
      opacity: 0.3;
      border: 0.1rem solid $medium-divider-color;
      margin-left: -0.1rem;
      &:first-child {
        margin-left: 0;
        border-top-left-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
      }
      &:last-child {
        border-top-right-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
      }
      &.selected {
        background-color: $default-background-color;
      }
      &:hover,
      &.selected {
        opacity: 1;
      }
      &.center {
        border-left: 0.1rem solid $medium-divider-color;
        border-right: 0.1rem solid $medium-divider-color;
      }

      a {
        display: block;
        padding: 0.7rem;
        color: $body-font-color;
        text-decoration: none;
        font-weight: bold;
      }

      span.to-do {
        margin-left: 0.5rem;
        padding: 0.2rem 0.8rem;
        background-color: $primary-color;
        border-radius: 10rem;
        color: white;
        font-size: 1.2rem;
      }
    }
  }

  .tab-target {
    display: none;
    &.selected {
      display: block;
    }
  }

  .assignments-list {
    table {
      display: none;
      width: 100%;
      border-collapse: collapse;
      font-size: 1.4rem;
      @include desktop {
        display: table;
      }

      .align-right {
        padding-right: 2rem;
        text-align: right;
        white-space: nowrap;
        width: 1%;
      }

      thead {
        th {
          padding: 0.7rem;
          text-align: left;
          border-bottom: 0.2rem solid #ddd;
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background: white;
          }

          td {
            padding: 0.7rem 1rem;
            &.date,
            &.time {
              white-space: nowrap;
            }
            &.action {
              a {
                color: $primary-color;
              }
            }
          }
        }
      }
    }

    .mobile-assignments {
      display: block;
      width: 100%;
      @include desktop {
        display: none;
      }
    }

    .assignment-mobile {
      position: relative;
      width: 100%;
      min-width: 25rem;
      padding: 1.5rem 0;
      font-size: 1.4rem;
      line-height: 2.2rem;
      border-bottom: 0.1rem solid $light-divider-color;

      .company {
        margin-right: 3rem;
        font-size: 1.5rem;

        span.assignment-id {
          color: $primary-color;
        }

        a.open-pdf {
          margin-right: 0.5rem;
          text-decoration: none;
          color: #888;
        }

        a.action {
          position: absolute;
          top: 1rem;
          right: 1rem;
          font-size: 2rem;

          i {
            color: $approval-font-color;
            &:hover {
              color: darken($approval-font-color, 20);
            }
          }
        }
      }

      .date {
        color: lighten($portal-body-font-color, 50);
      }
    }
  }
}

body.php {
  .block {
    margin: 3rem 0;

    &.with-tables {
      overflow: auto;
    }

    table.expense-totals {
      border-collapse: collapse;
      white-space: nowrap;

      td,
      th {
        padding: 0.5rem;
        text-align: right;
      }

      &.totals-table td {
        border-top: 0.1rem solid $medium-divider-color;
      }
    }

    table:not(.expense-totals) {
      tbody tr {
        display: block;

        td:first-child {
          display: block;
          padding-top: 1rem;
          font-weight: bold;
        }

        input.date {
          width: 5rem;
        }

        &:nth-last-child(3) table {
          tr {
            display: table-row;

            td {
              display: table-cell;

              select {
                width: 16rem;
                margin-right: 2rem;
              }

              input.small {
                margin-top: 0.8rem;
              }
            }
          }
        }
      }
    }
  }

  input[type="submit"] {
    margin: 2rem 0;
  }

  .uploadedfiles .file,
  .uploadarea {
    position: relative;
    max-width: 40rem;
    padding: 1rem;
    margin-bottom: 1rem;
    background: $light-background-color;
    border: 1px solid $medium-divider-color;
    border-radius: 4px;

    input[type="submit"] {
      position: absolute;
      top: 1rem;
      right: 1rem;
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      &:hover {
        color: $primary-color;
      }
    }
  }

  a.back-to {
    display: block;
    margin-bottom: 2rem;
  }
}
