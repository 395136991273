body.insite {
  .readable-width {
    margin: 0 auto;
    max-width: 50rem;
  }

  ol.manual {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      padding: 3rem 0;
      border-bottom: 0.1rem solid $light-divider-color;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: 0;
      }

      h3 {
        margin-top: 0;
      }

      img {
        max-width: 100%;
        box-shadow: 0.2rem 0.5rem 2rem rgba(0, 0, 0, 0.08);
      }

      ul {
        margin: 1rem 0 2rem;

        li {
          margin: 1rem 0;
        }
      }
    }
  }
}
