.availability {
  main {
    padding-bottom: 4rem;

    .filters .grouped-selection {
      @include desktop() {
        display: none;
      }
    }

    .grouped-selection {
      display: inline-block;
      position: relative;
      background: white;

      select {
        position: relative;
        z-index: 1;
        width: 6.6rem;
        border-radius: 0.4rem;
        border: 0.1rem solid $light-divider-color;
        background: transparent;
        color: transparent;
        outline: none;
        line-height: 3.5rem;
        cursor: pointer;
      }

      &:before,
      &:after {
        position: absolute;
        float: right;
        top: 0.1rem;
        z-index: 0;
        font-family: 'Font Awesome 5 Pro';
        font-weight: normal;
        font-style: normal;
        text-decoration:none;
      }

      &:before {
        content: '\f274';
        left: 1.2rem;
        line-height: 3.5rem;
        font-size: 2rem;
      }

      &:after {
        content: '\f107';
        right: 1.3rem;
        top: 0.8rem;
      }
    }

    #availabilities {
      display: flex;

      .month-table {
        display: none;
        width: 100%;
        &.selected {
          display: block;
        }
        @include wide-desktop() {
          &.selected + .month-table {
            display: block;
            margin-left: 3rem;
          }
        }
        @include wider-desktop() {
          &.selected + .month-table + .month-table {
            display: block;
            margin-left: 3rem;
          }
        }

        h3.desktop-title {
          @include mobile-only() {
            display: none;
          }

          .grouped-selection {
            margin-left: 1rem;
            font-size: 1.5rem;

            select {
              height: 3.8rem;
            }
          }
        }

        table {
          width: 100%;
          border-collapse: collapse;
          border-spacing: 0;

          thead {
            th {
              span {
                position: relative;
                left: -50%;
                font-size: 1.3rem;
                color: #888;
                font-weight: normal;
              }
            }
          }

          tbody {
            td.unavailable{
              width: 12.5%;
              height: 2.8rem;
              background-color: darken($grey-background-color, 5);
              cursor: default;
              i {
                display: block;
                font-size: 1.2rem;
                text-transform: capitalize;
                color:  #505050;
                margin-left: 0.5rem;
              }
              &:hover {
                background-color: none;
              }
            }

            td {
              width: 12.5%;
              height: 2.8rem;
              // min-width: 3.4rem;
              border: 1px solid $medium-divider-color;
              background-color: $grey-background-color;
              transition: background-color 0.15s ease-in-out;
              cursor: pointer;
              &:hover {
                background-color: darken($grey-background-color, 5);
              }
              &:first-child {
                min-width: auto;
                background-color: transparent;
                border: none;
                white-space: nowrap;
                text-align: right;
                vertical-align: middle;
                padding-right: 1rem;
                font-size: 1.4rem;
                &:hover {
                  color: $primary-color;
                }
              }
              &.full-day {
                width: 3.4rem;
              }

              i {
                display: none;
              }

              &.selected {
                vertical-align: middle;
                text-align: center;
                background-color: #E2EDD6;
                border: 1px solid #BAD0A1;
                i {
                  display: block;
                  color: #B6D288;
                }
              }
            }
          }
        }
      }
    }
  }
}
