.top-navigation {
  padding: 0 2.6rem;
  position: relative;
  background: linear-gradient(33.47deg, $primary-color-gradient 0%, $primary-color 100%);
  height: 7.1rem;

  @media print {
    display: none;
  }

  a {
    font-size: 2rem;
    line-height: 6.4rem;
    color: white;
    text-decoration: none;

    i {
      margin-right: 1rem;
      opacity: 0.5;
    }
  }
}
