.filters {
  display: flex;
  flex-wrap: wrap;
  @include wider-desktop {
    position: absolute;
    right: 2rem;
    top: 2.5rem;
    margin-bottom: 0;
    align-items: center;
  }
  &.overview{
    position: relative;
    top: 0rem;
    right: 0rem;
  }

  @media print {
    display: none;
  }

  .arrows {
    margin-right: 1.5rem;
  }

  .arrows,
  .toggle {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    text-align: center;
    @include wider-desktop {
      margin-bottom: 0;
      margin-right: 1.5rem;
    }

    .arrow,
    .toggle-option {
      border-radius: 0.4rem;
      background-color: white;
      border: 0.1rem solid $light-divider-color;
      cursor: pointer;
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }

      a,
      i {
        padding: 1.3rem 1.6rem;
        color: $portal-body-font-color;
        font-size: 1.5rem;
        @include tablet {
          padding: 1.3rem 2rem;
        }
        &:hover {
          text-decoration: none;
          background: $grey-background-color;
        }
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;

        a,
        i {
          background: unset;
        }
      }
    }

    .toggle-option:not(.selected) {
      background-color: $medium-background-color;

      i {
        opacity: 0.3;
      }
    }
  }

  .filter {
    &.disabled,
    &.loading {
      background-color: $medium-background-color;
      opacity: 0.5;
      &:focus {
        border-color: $medium-divider-color;
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
    &.loading {
      cursor: wait;
    }
  }

  .period-selector {
    margin-bottom: 1.5rem;
    position: relative;
    @include wider-desktop {
      margin-bottom: 0;
    }

    select {
      margin-right: 1.5rem;
      min-width: 10rem;
      border-width: 0.1rem;
    }
  }

  input.week {
    height: 3.5rem;
  }
}
