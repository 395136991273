body.schedules,
.blueprint-editor-view,
.period-planner-view,
.schedule-view {
  .filters .period-selector select {
    display: inline-block;

    &#week {
      padding-right: 5rem;

      @include mobile-only {
        padding-right: 4.5rem;
      }
    }
  }

  .planner-link {
    @include mobile-only {
      position: absolute;
      top: 2rem;
      right: 1rem;
    }

    a {
      display: block;
      margin-right: 2rem;
      white-space: nowrap;
      color: $portal-body-font-color;

      @include mobile-only {
        color: white;
      }
    }
  }

  .schedule {
    display: block;

    @include mobile-only {
      margin-top: 2rem;
    }

    header {
      margin: 2rem 0;

      @include wider-desktop {
        display: flex;
        justify-content: flex-start;
        margin: -1.5rem 0 3rem;
      }

      h3 {
        display: none;
        margin: 0 2rem 0 0;

        @include wider-desktop {
          display: block;
        }

        span {
          color: $light-font-color;
        }
      }

      .hours-summary {
        padding: 2rem;
        background: $grey-background-color;
        border-radius: 0.5rem;

        @include desktop {
          display: flex;
          padding: 0;
        }

        @include wider-desktop {
          justify-content: flex-end;
        }

        table {
          line-height: 1.2;
          font-size: 1.4rem;
          width: 100%;
          white-space: nowrap;

          @include desktop {
            width: auto;

            & + table {
              margin-left: 5rem;
            }
          }

          @include mobile-only {
            & + table {
              margin-top: 1rem;
              border-top: 0.1rem solid darken($grey-background-color, 10);

              tr:first-of-type {
                th,
                td {
                  padding: 1rem 0;
                }
              }
            }
          }

          th {
            text-align: left;
          }

          td {
            padding-left: 3rem;
            text-align: right;

            .minus {
              color: $primary-color;
            }

            .plus {
              color: $approval-font-color;
            }
          }
        }
      }
    }

    .week {
      .row {
        display: flex;

        &.row-week {
          position: sticky;
          z-index: 49;

          .date {
            font-size: 1.4rem;
            font-weight: bold;
          }

          .bar {
            border: 0;

            .hour {
              background: none;
              align-items: center;

              &:nth-child(odd) {
                background: none;
              }
            }
          }
        }

        &.outside-month {
          opacity: 0.3;
        }

        &.row-saturday,
        &.row-sunday {
          .bar .hour {
            background: #f8f8f8;

            &:nth-child(odd) {
              background: #f3f3f3;
            }
          }
        }

        .date {
          position: relative;
          width: 7.5rem;
          text-align: right;
          font-size: 1.3rem;
          line-height: 2rem;
        }

        .bar {
          display: flex;
          position: relative;
          flex-grow: 100;
          height: 2.6rem;
          border: 0.1rem solid $light-divider-color;

          .hour {
            width: 5%;
            height: 100%;
            background: white;
            color: #888;
            font-size: 1.3rem;

            &:nth-child(odd) {
              background: #f8f8f8;

              span {
                background: none;
              }
            }

            &:nth-child(3n + 1) span {
              opacity: 1;
            }

            span {
              margin-left: -0.4rem;
              opacity: 0;
              transition: opacity 0.3s;

              @include wide-desktop() {
                opacity: 1;
              }
            }
          }

          .break {
            position: absolute;
            pointer-events: none;
            padding: 0;
            height: 2.4rem;
            z-index: 3;
            background: rgba(white, 0.3);
          }

          .planning {
            display: flex;
            justify-content: space-between;
            position: absolute;
            height: 2.4rem;
            overflow: hidden;
            cursor: pointer;

            &:not(.expanded) {
              @include desktop() {
                border-right: 0.1rem solid white;
                border-left: 0.1rem solid white;
              }
            }

            > span {
              display: inline-block;
              padding: 0.2rem 0.7rem;
              font-size: 1.2rem;
              color: white;
              white-space: nowrap;

              &.start {
                .name {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      .holidays {
        font-size: 1.3rem;
        text-align: right;

        span.label {
          font-weight: bold;
        }
      }
    }
  }
}

body #personal-schedule,
body #shift-schedule {
  .week {
    &:first-child .row.row-week {
      margin-top: 0;
    }

    .row {
      &.row-week {
        margin-top: 2rem;
      }

      .date {
        span {
          position: absolute;
          right: 1.5rem;
          white-space: nowrap;
        }
      }

      .bar {
        margin-top: -0.1rem;

        .planning {
          background: #94ba6c;

          &.absence {
            background-color: #999;
          }
        }
      }
    }
  }
}

body #team-schedule {
  .week {
    &:first-child .row.row-week {
      margin-top: 0;
    }

    .row {
      margin-top: 4.5rem;

      @include mobile-only {
        display: block;
        margin-top: 7rem;
      }

      &.row-week {
        .date {
          span {
            white-space: nowrap;
            text-align: right;
            font-size: 2rem;
          }
        }
      }

      &.row-count-2 {
        .bar {
          height: 5rem;

          .planning + .planning {
            top: 2.5rem;
          }
        }
      }

      &.row-day {
        position: relative;

        .date {
          position: absolute;
          margin-top: -5.5rem;
          text-align: left;
          font-size: 1.6rem;
          width: auto;

          @include desktop {
            &.multiple-teams {
              margin-top: -0.4rem;
            }
          }

          @include mobile-only {
            &.multiple-teams {
              margin-top: -6rem;
            }
          }
        }

        .teams {
          flex-grow: 100;

          .team {
            display: flex;
            border: 0.1rem solid $light-divider-color;

            &:not(:last-child) {
              border-bottom: none;
            }

            @include desktop {
              .bar {
                border: none;
              }

              .team-name {
                width: 26rem;
                white-space: nowrap;
                padding: 0 0.5rem;
                border-right: 0.1rem solid $light-divider-color;
                font-size: 1.2rem;
                background: white;
              }

              .hour {
                background: white;

                &:nth-child(odd) {
                  background: #f8f8f8;
                }
              }
            }

            @include mobile-only {
              position: relative;
              margin-top: 2rem;

              .team-name {
                position: absolute;
                top: -2.1rem;
                font-size: 1.2rem;
              }

              .hour span {
                margin-top: -4rem;
              }
            }
          }
        }

        @include desktop {
          .date {
            position: relative;
            margin-top: -2.5rem;
            width: 5rem;
            font-size: 1.3rem;

            span {
              display: block;
              text-align: center;
              line-height: 2rem;
            }

            span.number {
              font-size: 2.5rem;
            }
          }
        }

        @media print {
          .date {
            position: relative;
            margin-top: -2.5rem;
            width: 5rem;
            font-size: 1.3rem;

            span {
              display: block;
              text-align: center;
              line-height: 2rem;
            }

            span.number {
              font-size: 2.5rem;
            }
          }
        }
      }

      .bar {
        .hour {
          span {
            display: block;
            margin-top: -2.5rem;
          }
        }

        .planning {
          background: rgba(#aaa, 0.5);

          span.end {
            @include desktop() {
              display: block;
            }
          }
        }
      }
    }
  }
}

body.portal .period-planner-view {
  th {
    position: sticky;
    top: -0.1rem;
    z-index: 9;
    background: $grey-background-color;
  }

  #shift-schedule .week .row .bar .planning > span {
    margin-top: 0;
  }
}
