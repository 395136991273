a.button,
button {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  color: unset;
  white-space: nowrap;
  &:hover {
    text-decoration: none;
  }

  i {
    margin-left: 0.5rem;
  }

  &.centered {
    display: block;
    margin: 3rem auto;
  }

  &.right {
    display: block;
    margin: 0.5rem 0 3rem auto;
  }

  &.full-width {
    width: 100%;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.grey-button,
  &.dark-grey-button {
    background: #eee;
    border: 1px solid #eee;
    &:hover:not(.disabled) {
      background: #ddd;
      border-color: #ddd
    }
  }

  &.white-button {
    background: white;
    border: 1px solid $body-font-color;
    &:hover:not(.disabled) {
      background: #f3f3f3;
    }
  }

  &.red-button {
    padding: 1rem 2rem;
    border-bottom: 0.5rem solid darken($primary-color, 10);
    background-color: $primary-color;
    color: white;
    border: 1px solid $primary-color;
    &:hover {
      background-color: #bc141b;
      color: white;
      border-color: #bc141b;
    }

    i {
      margin-left: 0.3rem;
    }
  }

  &.blue-button {
    background-color: #2D89F5;
    color: white;
    border: 1px solid #2D89F5;

    &:hover:not(.disabled) {
      background-color: #2c5bf5;
      color: white;
      border-color: #2c5bf5;
    }
  }
}
