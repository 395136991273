nav {
  display: flex;
  flex-direction: column;
  margin: 3.5rem 4.5rem;

  a {
    position: relative;
    font-size: 1.6rem;
    padding: 1.9rem 0 1.9rem 4rem;
    color: $body-font-color;
    &:visited {
      color: $body-font-color;
    }
    &:not(:nth-last-child(-n + 2)) {
      border-bottom: 1px solid #eaeaea;
    }
    @include desktop() {
      &:nth-last-child(2) {
        border-bottom: 1px solid #eaeaea;
      }
    }
    &:hover {
      text-decoration: none;
    }

    i {
      position: absolute;
      left: 0;
      font-size: 2rem;
      width: 3rem;
      text-align: center;
      margin-right: 1rem;
    }

    &.sign-out {
      padding: 1.9rem 0;
      margin: 0 auto;
      color: #ccc;
      font-size: 1.5rem;
      text-transform: lowercase;

      i {
        position: static;
        margin-left: 0.3rem;
        width: auto;
        font-size: 1.4rem;
        &:before {
          vertical-align: middle;
        }
      }
    }
  }
}
