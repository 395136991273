body.absences {
  main > section {
    @include desktop {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    > header {
      width: 100%;
    }

    .vacation-section .submission-block .right {
      margin-right: 3rem;
    }

    .request-leave {
      width: 100%;
      margin-right: 2rem;
      @include wide-desktop {
        width: unset;
        margin-right: unset;
      }
    }

    .submission-block{
      display: flex;
      justify-content: space-between;
      padding: 1.5rem 3rem;
      border-radius: 0.4rem;
      box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);

      &.hidden{
        display: none;
      }

      &:last-child{
        box-shadow: none;
      }
      .left{
        h4.description{
          font-weight: bold;
          margin: 0rem;
          margin-bottom: 1rem;
        }
        span{
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;

          p.dates{
            font-size: 1.4rem;
            margin: 0rem;
            padding-left: 1rem;
          }
        }

        a{
          &:hover{
            text-decoration: none;
          }
          .delete-button{
            display: flex;
            align-items: center;
            color: $primary-color;

            p{
              margin: 0rem;
              padding-left: 1rem;
            }
          }
        }
      }

      .right{
        text-align: right;
        p {
          margin: 0rem;
          &.days{
            margin-bottom: 0.4rem;
            font-weight: bold;
          }
          &.status{
            font-style: italic;
            margin-bottom: 0.5rem;
            &.green{
              color: $approval-font-color;
            }
            &.grey{
              color: $light-font-color;
            }
            &.red {
              color: $warning-font-color;
            }
          }
        }

        span{
          display: flex;
          justify-content: flex-end;
          align-items: center;

          i{
            margin-right: 0.2rem;
            font-size: 1rem;
          }
        }
      }
    }

    .vacation-quota{
      justify-content: space-between;
      padding: 1.5rem 3rem;
      border-radius: 0.4rem;
      box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);
      display: none;

      &.show{
        display: flex;
      }

      &.red{
        background-color: red;
      }

      &:last-child{
        box-shadow: none;
      }

      h4,p{
        margin: 0rem;
      }

      .occasion{
        h4{
          font-weight: bold;
          margin-bottom: 1rem;
        }

        span{
          display: flex;
          align-items: center;
          i{
            margin-right: 1rem;
          }

          p{
            font-size: 1.4rem;
          }
        }
      }

      .data{
        margin-right: 3rem;

        p{
          text-align: right;
          font-weight: bold;

          &.expiry{
            font-size: 1.4rem;
            font-style: italic;
            font-weight: normal;
            color: $light-font-color;
            margin-bottom: 0rem;
          }
        }

        span{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 0.9rem;

          i{
            margin-right: 0.2rem;
            font-size: 1rem;
          }
        }
      }
    }

    .content {
      @include desktop {
        margin-right: 2rem;
        flex-grow: 1;
        width: 50%;
      }

      .statistics {
        .vacation-block{
          background-color: white;
          box-shadow: 0 0.5rem 1.6rem 0 rgba(0,0,0,0.12);
          margin-bottom: 2rem;
          border-radius: 0.4rem;

          table{
            width: 100%;
            tr{
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              padding: 1.5rem 3rem;
              background-color: white;
              border-radius: 0.4rem;
              box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);



              &:last-child{
                box-shadow: none;
              }

              td{
              h4{
                  font-weight: bold;
                  margin: 0rem;
                }

                &.days-left{
                  margin-right: 3rem;
                  text-align: right;
                  p{
                    margin: 0rem;

                    &.days{
                      font-weight: bold;
                      margin-bottom: 1rem;
                    }
                    &.expiry{
                      color: $light-font-color;
                      font-style: italic;
                    }
                  }
                }
                }
            }
          }

          .header{
            padding: 2rem 3rem 1.5rem 3rem;
            text-align: left;
            &.overview{
              display: flex;
              justify-content: space-between;

              #year-selector{
                width: 8.5rem;
              }
            }

            strong.disclaimer {
              display: block;
              margin-top: 0.3rem;
              color: $primary-color;
            }

            .usage-description {
              color: $light-font-color;
              text-align: left;
              margin-bottom: 1.5rem;
            }
          }

          .usage-bar {
            position: relative;
            background-color: $dark-background-color;
            text-align: center;
            color: $contrast-font-color;
            z-index: 1;
            border-radius: 2rem;

            .usage-bar-text {
              position: relative;
              padding: 0.2rem;
              z-index: 3;
              font-weight: bold;
            }

            .usage {
              position: absolute;
              z-index: 2;
              top: 0;
              left: 0;
              max-width: 100%;
              height: 100%;
              background: linear-gradient(180deg, $primary-color-gradient 0%, $primary-color 100%);
              border-radius: 2rem;
            }
          }
          .vacation-table{
            .vacation-section{
              border-radius: 0.4rem;
              box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);

              .title{
                display: flex;
                padding: 2.5rem 3rem 2.5rem 3rem;

                &.clicked{
                  padding: 2.5rem 3rem 1rem 3rem;
                }

                &:hover {
                  cursor: pointer;
                  background-color: #F5F5F5;
                }

                .description {
                  flex-grow: 1;
                }

                h4{
                  font-weight: bold;
                  font-size: 1.8rem;
                  margin: 0rem;
                  margin-bottom: 1rem;
                }

                .summary {
                  text-align: right;

                  p.sum {
                    font-size: 1.4rem;
                    font-style: italic;
                  }
                }

                i {
                  display: flex;
                  width: 3rem;
                  font-size: 2rem;
                  align-items: center;
                  justify-content: flex-end;
                }

                p{
                  color: $light-font-color;
                  margin: 0rem;

                  &.left {
                    margin-bottom: 1rem;
                    line-height: 1.6rem;
                    font-weight: bold;
                    &.gained {
                      color: $approval-font-color;
                    }
                    &.used {
                      display: flex;
                      align-items: center;
                      margin: 0;
                      color: $primary-color;
                    }
                  }
                }
              }
            }
          }
        }



      }

      .toggle-details {
        margin-bottom: 4rem;
        text-align: center;
        @include desktop() {
          display: none;
        }
      }

      .hide-details {
        display: none;
      }
    }

    .sub-text{ p{
      color: $light-font-color;
      max-width: 40rem;
      text-align: center;
      margin: auto;
    }}

    aside.submited-requests{
      margin-bottom: 2rem;
      background-color: $medium-background-color;
      flex-grow: 1;
      border-radius: 0.4rem;

      @include desktop {
        background: white;
        box-shadow: 0 0.5rem 1.6rem 0 rgba(0,0,0,0.12);
        max-width: 50rem;
      }

      @include wider-desktop() {
      }

      .header{
        padding: 2rem 3rem;
        h3{
          margin: 0rem;
          margin-bottom: 0.7rem;
        }

        p.sub-text{
          font-style: italic;
          font-weight: normal;
          color: $light-font-color;
          margin: 0rem;
        }

      }
    }

    div {aside.request-leave {
      flex-grow: 1;
      text-align: center;
      border-radius: 0.4rem;
      min-width: 38rem;
      margin-bottom: 3rem;
      padding: 3rem;
      background-color: $medium-background-color;
      @include desktop {
        background: white;
        box-shadow: 0 0.5rem 1.6rem 0 rgba(0,0,0,0.12);
        max-width: 50rem;
      }

      @include wider-desktop() {
      }

      h3 {
        margin: 0 0 2.5rem;
        text-align: left;
      }

      p {
        text-align: left;
      }

      input#comments {
        width: 100%;
        margin-bottom: 1rem;
      }

      #days {

        .day {
          display: flex;
          justify-content: space-between;

          input {
            margin-right: 1.6rem;
            width: 100%;
            flex-grow: 1;
          }

          .input-wrapper {
            flex-grow: 1;
            min-width: 11rem;

            i {
              top: 0.8rem;
            }

            select {
              width: 100%;
            }
          }
        }

        select, .date {
          margin: 0.5rem 0;
        }
      }

      input[type="submit"] {
        margin: 2rem auto 0;
      }

      .add-row {
        display: block;
        margin: 1rem 0 3rem;
        padding: 1rem;
        width: 100%;
        background-color: darken($medium-background-color, 8);
        border-radius: 0.4rem;
        font-weight: bold;
        text-decoration: none;
        color: lighten($portal-body-font-color, 30);

        i {
          margin-left: 1rem;
        }
      }
    }
  }

    .absence-details {
      @include mobile-only {
        display: none;
      }

      .summary {
        table {
          width: 100%;
          border-collapse: collapse;

          td, th {
            padding: 1.2rem 2rem;
            text-align: left;
            @include mobile-only() {
              &.last-year, span {
                display: none;
              }
            }
            &.value {
              text-align: right;

              .static-tooltip-icon .tooltip-body.extra-hours-specification {
                width: 45rem;

                .tooltip-content {
                  padding-bottom: 3rem;

                  h3 {
                    margin-left: 2rem;
                    font-weight: normal;
                  }
                }

                @include mobile-only() {
                  width: 25rem;
                  transform: translateX(-68%);

                  .arrow {
                    left: calc(68%);
                  }
                }
              }
            }
          }

          tbody tr {
            background-color: $medium-background-color;
            border-bottom: 0.1rem solid white;
            @include desktop() {
              background-color: $default-background-color;
              border-bottom: 0.1rem solid $medium-background-color;
            }
            &.totals {
              font-weight: bold;
            }
          }
        }
      }

      .submitted, .summary, .past {
        margin-bottom: 5rem;

        .absence {
          margin: 1rem 0;
          padding: 2rem;
          background-color: $medium-background-color;
          @include desktop() {
            background: white;
          }

          .description {
            position: relative;
            margin-bottom: 0.5rem;

            span.text {
              margin-right: 10rem;
              font-size: 1.5rem;
              font-weight: bold;
            }

            span.status {
              position: absolute;
              top: 0;
              right: 0;
              color: lighten($portal-body-font-color, 50);
              font-style: italic;
              font-size: 1.3rem;
              &.green {
                color: $approval-font-color;
              }
              &.red {
                color: $primary-color;
              }

              span.delete {
                display: inline-block;
                margin-left: 0.5rem;

                a {
                  text-decoration: none;
                  font-style: normal;
                }
              }
            }

            span.days {
              position: absolute;
              top: 2.5rem;
              right: 0;
              font-size: 1.3rem;
            }
          }

          .absence-day {
            padding: 0.3rem 0;
            font-size: 1.4rem;

            i {
              margin-right: 0.5rem;
            }

            span.duration {
              font-style: italic;
            }
          }
        }
      }
    }
  }
}
