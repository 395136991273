.days-selection {
  .in-line {
    display: flex;
    flex-direction: row;
    input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
    }
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      background-color: #ccc;
      color: white;
      cursor: pointer;
      margin-right: 0.3rem !important;
    }

    input[type="checkbox"]:checked + label {
      background-color: #2d89f5;
      color: white;
    }
  }
}
