body.assignments .declaration-page.view-only {
  form {
    grid-gap: 0;

    input, select, textarea {
      background: none;
      border:none;
      padding: 0;
      line-height: 2.1rem;
      font-size: 1.5rem;
      color: rgb(84, 84, 84);
      height: auto;
    }

    a.show-locations {
      display: block;
      margin-bottom: 2rem;
      text-align: center;
      @include desktop {
        display: none;
        margin: 0;
      }
    }

    .locations.invisible {
      display: none;
      @include desktop {
        display: block;

        .location:first-of-type {
          margin-top: 0;
        }
      }
    }

    section.details,
    section.submit {
      @include desktop {
        padding: 10%;
      }
    }

    section.submit {
      @include desktop {
        grid-area: preferences;
        display: block;
        background: none;

        .declaration-summary {
          margin-bottom: 2rem;
          div:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
