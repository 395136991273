h1 {
  margin: 0 0 2.8rem;
  font-size: 2.6rem;
  line-height: 3.2rem;
  margin-bottom: 3.2rem;

  i {
    margin-right: 1rem;
  }
}

h2 {
  margin: 0 0 2.2rem;
  font-size: 2.2rem;
  line-height: 2.8rem;
}

h3 {
  margin: 1.4rem 0;
  font-size: 2rem;
  font-weight: bold;

  small {
    font-size: 1.5rem;
    color: $light-font-color;
  }
}

h4 {
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: normal;
}

h1,
h2,
h3,
h4 {
  &.centered {
    text-align: center;
  }
}

p {
  font-size: 1.5rem;
  margin: 0 0 1.5rem;
  line-height: 2.2rem;
  &.introduction {
    font-size: 2rem;
    line-height: 2.6rem;
    color: $primary-color;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  &:visited {
    color: $link-color;
  }
  &.open-pdf {
    margin-right: 0.4rem;
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.fa-file-pdf {
  color: #888;
  &:hover {
    color: $portal-body-font-color;
  }
}
