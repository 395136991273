.double-spinner {
  display: none;
  margin-top: 2rem;
  width: 3.2rem;
  height: 3.2rem;
  &:after {
    content: " ";
    display: block;
    width: 2.3rem;
    height: 2.3rem;
    margin: 0.1rem;
    border-radius: 50%;
    border: 0.3rem solid black;
    border-color: black transparent black transparent;
    animation: double-spinner 1.2s linear infinite;
    -webkit-animation: double-spinner 1.2s linear infinite;
  }

  &.inline {
    margin: 0 0 0 2rem;
    width: 2rem;
    height: 2rem;
    &:after {
      width: 1.6rem;
      height: 1.6rem;
      border: 0.2rem solid black;
      border-color: black transparent black transparent;
    }
    &.small {
      margin: 0;
    }
  }

  &.small {
    width: 1.2rem;
    height: 1rem;
    &:after {
      width: 0.8rem;
      height: 0.8rem;
    }
  }

  &.large {
    width: 9.6rem;
    height: 9.6rem;
    &:after {
      width: 6.9rem;
      height: 6.9rem;
      animation: double-spinner 2s linear infinite;
      -webkit-animation: double-spinner 2s linear infinite;
    }
  }
}
@keyframes double-spinner {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
