/* Backgrounds */
$default-background-color: #ffffff;
$light-background-color: #eeeeee;
$grey-background-color: #f8f8f8;
$medium-background-color: #f3f3f3;
$dark-background-color: #888888;
$approval-background-color: #edf6e4;
$light-blue-background-color: #e1eaf4;
$light-orange-background-color: #f6e6d5;
$warning-background-color: #FFD2D2;

/* Tables */
$light-divider-color: #dddddd;
$mediumlight-divider-color: #d8d8d8;
$medium-divider-color: #cccccc;
$approval-border-color: #78b732;
$warning-border-color: #EC001A;
$approval-border-gradient: #8ec75d;

/* Text */
$body-font-color: #555555;
$light-font-color: #444444;
$portal-body-font-color: #333333;
$error-message-color: #fff0f0;
$contrast-font-color: #ffffff;
$link-color: #1c6ac6;
$light-font-color: #989898;
$light-orange-font-color: #cf8e4a;
$bouwens-primary: #d8232a;
$bouwens-primary-gradient: #ea585d;
$receptel-primary: #222A5F;
$receptel-primary-gradient: #273771;
$receptel-secondary: #00A79B;
$receptel-tertiary: #FAA61A;
$approval-font-color: #78b732;
$warning-font-color: #EC001A;
$blue-font-color: #1c6ac6;
